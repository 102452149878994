<template>
    <v-layout column="" pr-3 mr-2 mt-1>
        <v-dialog v-model="Advertising_dialog" max-width="800" max-height="400" v-if="Advertising_dialog" persistent>



            <div>
                <v-form ref="form" v-model="valid">
                    <v-card pa-4>

                        <v-card-title class="headline grey lighten-2" primary-title
                            style="	font-family: 'droid_bold' !important;    background-color: #435c6f!important;color:#fff">




                            <span>تفاصيل الاعلان</span>

                        </v-card-title>


                        <v-container>


                            <v-flex pa-3 xs12 v-if="editedIndex>=0">
                                <v-select background-color="#ffffff" height="50" :rules="nameRules" class="my-2"
                                    @change="type_advertising_method()" :items="advertising_type" item-value="id"
                                    disabled item-text="name" v-model="type_advertising" placeholder="نوع الاعلان"
                                    required>
                                </v-select>
                            </v-flex>

                            <v-flex pa-3 xs12 v-else>
                                <v-select background-color="#ffffff" height="50" :rules="nameRules" class="my-2"
                                    @change="type_advertising_method()" :items="advertising_type" item-value="id"
                                    item-text="name" v-model="type_advertising" placeholder="نوع الاعلان" required>
                                </v-select>
                            </v-flex>



                            <v-flex pa-3 xs12 >

                                <div v-if="editedIndex>=0">
                   
                                     <div  v-if="type_advertising==2" >اسم الخدمه :  {{editedItem.item.item_name}}
                                 <br>
                                  <br>
                                 
                                 </div>
                                </div>

                                <div v-else>
                                    <v-select background-color="#ffffff" height="50" :rules="nameRules" class="my-2"
                                        :items="items" item-value="id" item-text="item_name"
                                        v-model="editedItem.item.id" placeholder="اسم الخدمه" required>
                                    </v-select>
                                </div>

                            </v-flex>







                            <v-layout>
                                <v-flex xs12>
                                    <v-textarea filled name="input-7-4" required v-model="editedItem.adv_description"
                                        :rules="descriptRules" label="وصف الاعلان" height="200" disabled>
                                    </v-textarea>

                                </v-flex>
                            </v-layout>

                            <v-flex pa-3 xs12>
                                <v-select background-color="#ffffff" height="50" :rules="nameRules" class="my-2"
                                    :items="advertising_types" item-value="id" item-text="advertising_type_name"
                                    disabled v-model="editedItem.advertising_type_id" placeholder="نوع الاشتراك"
                                    required>
                                </v-select>
                            </v-flex>


                            <v-row justify="center" class="mb-6">


                                <v-card height="200" width="200" class="card_img">

                                    <div v-if="editedItem.image.length !==0">

                                        <v-img :src="imgs_url" height="200" width="200" class="card_img">

                                        </v-img>
                                    </div>

                                    <div v-else>
                                        <!-- <v-img :src="editedItem.image[0].image_url" height="200" width="200" class="card_img"  > -->

                                    </div>




                                </v-card>

                            </v-row>


                            <v-container fluid>


                                <p>
                                    حاله الاعلان :

                                    <v-radio-group disabled v-model="editedItem.active" :mandatory="false"
                                        :rules="active">
                                        <v-radio label="مفعل" :value="1"></v-radio>
                                        <v-radio label="متوقف" :value="0"></v-radio>
                                    </v-radio-group>

                                </p>



                                <p>
                                    حاله النشر :

                                    <v-radio-group v-model="editedItem.is_admin_approved" :mandatory="false"
                                        :rules="active">
                                        <v-radio label="تفعيل" :value="1"></v-radio>
                                        <v-radio label="ايقاف" :value="0"></v-radio>
                                    </v-radio-group>

                                </p>




                            </v-container>

                            <br>
                            <br>

                            <v-layout row wrap pr-5>
                                <v-btn @click="submitـadvertisings()" color="success" style="margin-left:10px"
                                    width="150px">
                                    <span v-show="!loading">حفظ</span>
                                    <v-progress-circular indeterminate color="white" v-show="loading">
                                    </v-progress-circular>
                                </v-btn>
                                <v-btn @click="close_offer()" color="red" dark="" width="150px"> خروج</v-btn>
                            </v-layout>
                        </v-container>


                    </v-card>
                </v-form>
            </div>
        </v-dialog>

        <v-row>
            <v-data-table :headers="headers" :items="advertising" :page.sync="page" @page-count="pageCount = $event"
                sort-by="calories" class="elevation-1 request_table" style="width:96%" items-per-page="15"
                hide-default-footer="" :loading="loading" loading-text="جاري تحميل البيانات">

                <template v-slot:top>
                    <v-toolbar flat color="white" pb-5>

                        <v-toolbar-title>اعلانات التجار</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <!-- <v-spacer></v-spacer>
                      -->

                        <!-- <v-layout row wrap>
                            <v-flex xs12><h4 style="color:#000">عدد اعلانات التجار الفعاله: <span style="color:blue">{{ActiveOwnerAdverNum}}</span></h4></v-flex>
                            <v-flex xs12><h4 style="color:#000">عدد اعلانات الخدمات الفعاله:<span style="color:blue">{{ActiveItemsAdverNum}}</span></h4></v-flex>
                        </v-layout> -->



                    </v-toolbar>
                </template>






                <template v-slot:bottom>
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </template>



                <template v-slot:item.action="{ item }">
                    <v-btn text icon @click="editItem(item)">
                        <i class="fas fa-edit fa-lg"></i>
                    </v-btn>



                </template>

                <template v-slot:item.type="{ item }">
                    <span v-if="item.item.owner_barnd_name">
                        اعلان لجميع الخدمات
                    </span>

                    <span v-else>
                        اعلان خدمه
                    </span>




                </template>


                <template v-slot:item.name="{ item }">
                    <span v-if="item.item.owner_barnd_name">
                        لجميع المراكز
                    </span>

                    <span v-else>
                        {{item.item.item_name}}

                    </span>




                </template>


                <template v-slot:item.status="{ item }">


                    <v-chip class="res_pinding_chip" dark="" style="color:#fff !important" color="green"
                        v-if="item.is_admin_approved==1">
                        <span style="color:#fff !important">
                            فعال
                        </span>

                    </v-chip>

                    <v-chip class="res_pinding_chip" dark="" style="color:#fff" color="#f07821" v-else>

                        <span style="color:#fff !important">
                          معلق
                        </span>

                    </v-chip>




                </template>








            </v-data-table>

        </v-row>
        <v-row>
            <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left"
                circle="" :length="pageCount"></v-pagination>
        </v-row>
    </v-layout>

</template>



<script>
    const axios = require('axios');

    export default {

        data: () => ({
            type_advertising: '',
            item_id_offer: '',
            ActiveItemsAdverNum:'',
            ActiveOwnerAdverNum:'',
            submit_name: '',
             page: 1,
            pageCount: 0,
            current_page: 0,
            imgs_url: '',
            img_name: '',
            advertising: [],
            //img_url: 'http://109.224.27.9:81',
            item_id: '',
            name: '',
            images: null,

            advertising_type: [{
                    id: 1,
                    name: 'اعلان لجميع الخدمات'
                },
                {
                    id: 2,
                    name: 'اعلان لخدمه'
                }


            ],
            headers: [{
                    text: '#',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'اسم التاجر',
                    align: 'center',
                    sortable: false,
                    value: 'owner.owner_barnd_name',
                },

                {
                    text: 'رقم الهاتف',
                    align: 'center',
                    sortable: false,
                    value: 'owner.owner_phone',
                },


                {
                    text: 'نوع الاعلان',
                    align: 'center',
                    sortable: false,
                    value: 'type',
                },

                {
                    text: 'اسم الخدمه',
                    align: 'center',
                    sortable: false,
                    value: 'name',
                },



                {
                    text: ' نوع الاشتراك',
                    align: 'center',
                    sortable: false,
                    value: 'advertisingType.advertising_type_name',
                },

                  {
                    text: 'عدد الايام المتبقيه',
                    align: 'center',
                    sortable: false,
                    value: 'remaining_days_num',
                },





                {
                    text: 'الحاله',
                    align: 'center',
                    sortable: false,
                    value: 'status',
                },







                {
                    text: 'تاريخ الاضافة',
                    value: 'created_at',
                    sortable: false
                },


                {
                    text: ' ',
                    value: 'action',
                    align: 'center',
                },

            ],
            itemId: '',
            token: '',
            map_dialog: false,
            Advertising_dialog: false,
            modal2: false,
            items: '',
            editedItem: {
                id: '',
                owner_id: '',
                item_id: '',
                is_admin_approved: '',
                advertising_type_id: '',
                adv_description: '',
                active: '',
                item: {
                    id: ''
                },
                image: [


                ]


            },

            editedIndex: -1,


            valid: false,
            deduction: '',
            offer_info_id: '',
            img_cliced: -1,
            day_to_open: [],
            active: [
                v => !isNaN(v) || 'هذا الحقل مطلوب  ',
            ],
            nameRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
            ],
            numberRul: [
                v => !!v || 'هذا الحقل مطلوب  ',

                v => !isNaN(v) || 'يجب ادخال رقم ',
            ],
            numberRul1: [

                v => !isNaN(v) || 'يجب ادخال رقم ',
            ],
            descriptRules: [
                v => !!v || 'هذا الحقل مطلوب  ',
                //  v => v.length >= 20 || 'يجب ان يكون الوصف اكثر من ٢٠ حرف ',
            ],
            loading: false,



        }),



        methods: {


            editItem(item) {
                this.Advertising_dialog = true
                this.editedItem = Object.assign({}, item)
                this.imgs_url = this.Url + '/images/' + this.editedItem.image[0].image_url;

                  if (this.editedItem.item.owner_barnd_name) {
                    this.type_advertising = 1;



                } else {
                    this.type_advertising = 2;
                }
                this.editedIndex = this.advertising.indexOf(item)



            },
            type_advertising_method() {
                if (this.type_advertising == 1) {

                    this.editedItem.owner_id = this.$cookies.get('owner_info').id;
                } else if (this.type_advertising == 2) {

                  //  this.getItems();


                }


            },
            //   getItems() {

            //     //var url = "/v2/items/search?filter[owner.user_id]=" + this.$cookies.get('owner_info').id;
            //     var url = "/v2/items/owner/get?page=" + this.current_page;
            //     this.loading = true
            //     this.$http({
            //         method: 'get',
            //         url: url,
            //         headers: {

            //         }

            //     }).then(response => {
            //         this.items = response.data.data;
            //         this.last_page = response.data.meta.last_page;
            //         this.pageCount = response.data.meta.last_page;


            //     }).catch(error => {

            //         error


            //     }).finally(d => {
            //         d,
            //         this.loading = false;
            //     });

            // },



            getAllAdvTypes() {
                var url = "v2/owner/getAllAdvTypes";
                this.$http.get(url).then(response => {
                    this.advertising_types = response.data.data;
                })

            },

            getaAdvertising() {
                var url = "v2/Advertising/operation/getAllAdvs";
                this.$http.get(url).then(response => {
                    this.advertising = response.data.data;
                    this.ActiveOwnerAdverNum=response.data.meta.ActiveOwnerAdverNum;
                    this.ActiveItemsAdverNum=response.data.meta.ActiveItemsAdverNum;


                    this.last_page = response.data.meta.last_page;
                    this.pageCount = response.data.meta.last_page;


                })

            },




            close_offer() {
                this.editedIndex = -1;
                this.editedItem = {
                        id: '',
                        owner_id: '',
                        item_id: '',
                        advertising_type_id: '',
                        adv_description: '',
                        active: '',
                        item: {
                            id: ''
                        },
                        image: [


                        ]


                    },
                    //this.editedItem={};
                    this.Advertising_dialog = false;

            },

            submitـadvertisings() {


                // if (this.$refs.form.validate()) {



                var data = {
                    is_admin_approved: this.editedItem.is_admin_approved

                };



                var posturl = "/v2/Advertising/operation/changeStatus/" + this.editedItem.id;


                this.loading = true;
                axios({
                        method: 'post',
                        url: posturl,
                        data: data,

                    })
                    .then(response => {


                        if (this.editedIndex > -1) {
                            this.$swal("تم تعديل الاعلان ", "", "success")



                        }
                        this.Advertising_dialog = false;
                        this.$refs.form.reset();
                        this.getaAdvertising();

                        if (response.data.success == true) {
                            this.$refs.form.reset()
                            this.dialog = false;


                        }
                        //



                    })

                    .catch(error => {
                            this.err_show = true;
                            error
                          
                            if(error.response.data.data=='Notfound')
                            {
                                this.$swal("يجب ان لا يتجوز عدد اعلانات التجار عن ٧ اعلانات ", "", "error")
                            }
                        }

                    )
                    .finally(d => {
                        d,
                        this.offer_dialog = false;
                        this.loading = false;
                    });
                // } else {


                //     this.$swal('  خطاء', " يجب ملئ جميع الحقول   ", 'error');




                // }

            },
























            close() {
                this.dialog = false
                this.editedIndex = -1
                this.editedItem = {};
                this.$emit("Advertising_dialog", false);
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                }, 300)
            },



        },


        created() {



        },
        mounted() {
            this.getaAdvertising();


            this.getAllAdvTypes();




        },
        components: {


        }
    }
</script>